.videoRoot {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}
.videoPlaceholder {
    width: 100vw;
    height: 0;
}
.videoContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.videoContainer .video {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
}
.videoContainer .video iframe {
    margin: 0;
    padding: 0;
}
.videoContainer .videoDetails {
    width: 960px;
    height: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}