@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{background-color:#f7f7f7;align-items:center}.root{background-color:#f7f7f7;min-height:100vh;display:flex;justify-content:center;align-items:center}.form{background-color:#fff;width:25vw;height:50vh;flex-direction:column;display:flex;align-items:center;justify-content:flex-start;border-style:solid;border-color:#e1e1e1;border-width:0.1vh}.logo{background-color:#fff;width:25vw;height:20vh;display:flex;justify-content:center;align-items:center}.logoSrc{width:20vw}.formInput{width:20vw;height:3vh;margin-top:2vh;padding:0.5vh;font-size:1.5vh;font-weight:500;border-style:solid;border-color:#e1e1e1;border-width:0.2vh;outline:none;-webkit-appearance:none}

.loader{background-color:#fff;min-height:100vh;display:flex;justify-content:center;align-items:center}

.button{background-color:#F9A02C;width:21vw;height:4vh;display:flex;justify-content:center;align-items:center;text-align:center;margin-top:2vh;font-size:1.5vh;color:#fff;font-size:1.5vh;font-weight:600;border:none;text-decoration:none;outline:none;cursor:pointer;-webkit-appearance:none}

body{background-color:#f7f7f7}.root{background-color:#f7f7f7;min-height:100vh;display:flex;flex-direction:column}.container{width:100vw;height:90vh;background-color:#fff;display:flex;position:absolute;top:10.05vh}.sidebar{width:20vw;height:100%;background-color:#fff;border-right-width:0.05vh;border-right-style:solid;border-right-color:#f1f1f1}.sidebarLoader{width:100%;height:100%;display:flex;justify-content:center;align-items:center}.content{width:80vw;height:100%;background-color:#fff;display:flex;flex-direction:column;justify-content:center;align-items:center}.contentContainer{width:100%;height:100%;display:flex;flex-direction:row;align-items:flex-start;justify-content:flex-start;flex-wrap:wrap;overflow:auto}.item{width:25%;height:35vh;display:flex;align-items:center;justify-content:center;float:left}.itemData{width:85%;height:90%;background-color:#fff;border-color:#e1e1e1;border-width:0.1vh;border-style:solid;cursor:pointer}.itemData .imageContainer{width:100%;height:60%;background-color:#e1e1e1}.itemData .imageContainer .itemThumbnail{width:100%;height:100%}.itemData .detailsContainer{width:100%;height:40%;display:flex;flex-direction:column;align-items:center}.itemData .detailsContainer .itemDate{width:95%;height:25%;display:flex;align-items:center;font-family:'Roboto';font-weight:600;font-size:1.4vh;color:#8b8b8b}.itemData .detailsContainer .itemTitle{width:95%;height:45%;display:flex;align-items:flex-start;font-family:'Roboto';font-weight:600;font-size:1.8vh}.itemData .detailsContainer .itemDuration{width:95%;height:30%;display:flex;align-items:center;font-family:'Roboto';font-weight:600;font-size:1.5vh;flex-direction:row}.itemData .detailsContainer .itemDuration .icon{margin-right:0.1vw}

.navigation{width:100vw;height:10vh;background-color:#fff;border-bottom-width:0.05vh;border-bottom-style:solid;border-bottom-color:#f1f1f1;top:0;left:0;right:0;position:absolute;display:flex;flex-direction:row}.navigationLogo{width:20vw;height:100%;display:flex;align-items:center;justify-content:center}.navigationLogoSrc{width:80%}.navigationMenu{width:80vw;height:100%;background-color:#fff;display:flex;align-items:center;justify-content:flex-end;flex-direction:row}.navigationAccount{width:10vw;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center;font-family:'Roboto';font-weight:500;font-size:2.25vh;margin-left:2.5vw}.accountUsername{width:100%;height:50%;display:flex;align-items:flex-end;justify-content:flex-start;font-family:'Roboto';font-weight:500;font-size:2.2vh}.accountEmail{width:100%;height:50%;display:flex;align-items:flex-start;justify-content:flex-start;font-family:'Roboto';font-weight:500;font-size:1.5vh;color:#8b8b8b}.navigationItem{width:10vw;height:100%;display:flex;align-items:center;justify-content:center}.navigationItem a{text-decoration:none !important;color:#151515 !important;font-family:'Roboto';font-weight:500;font-size:2.25vh}.navigationItemCurrent{width:10vw;height:100%;display:flex;align-items:center;justify-content:center}.navigationItemCurrent a{text-decoration:none !important;color:#F9A02C !important;font-family:'Roboto';font-weight:500;font-size:2.25vh}

.videoRoot{width:100vw;height:100vh;display:flex;flex-direction:column}.videoPlaceholder{width:100vw;height:0}.videoContainer{width:100vw;height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:flex-start}.videoContainer .video{display:flex;flex-direction:column;justify-content:center;align-items:center;width:100vw}.videoContainer .video iframe{margin:0;padding:0}.videoContainer .videoDetails{width:960px;height:0;display:flex;justify-content:flex-start;align-items:center}

