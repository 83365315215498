body {
    background-color: #f7f7f7;
}

.root {
    background-color: #f7f7f7;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.container {
    width: 100vw;
    height: 90vh;
    background-color: #fff;
    display: flex;
    position: absolute;
    top: 10.05vh;
}

.sidebar {
    width: 20vw;
    height: 100%;
    background-color: #fff;
    border-right-width: 0.05vh;
    border-right-style: solid;
    border-right-color: #f1f1f1;
}
.sidebarLoader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content {
    width: 80vw;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contentContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: auto;
}

.item {
    width: 25%;
    height: 35vh;
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
}
.itemData {
    width: 85%;
    height: 90%;
    background-color: #fff;
    border-color: #e1e1e1;
    border-width: 0.1vh;
    border-style: solid;
    cursor: pointer;
}
.itemData .imageContainer {
    width: 100%;
    height: 60%;
    background-color: #e1e1e1;
}
.itemData .imageContainer .itemThumbnail {
    width: 100%;
    height: 100%;
}
.itemData .detailsContainer {
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.itemData .detailsContainer .itemDate {
    width: 95%;
    height: 25%;
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 1.4vh;
    color: #8b8b8b;
}
.itemData .detailsContainer .itemTitle {
    width: 95%;
    height: 45%;
    display: flex;
    align-items: flex-start;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 1.8vh;
}
.itemData .detailsContainer .itemDuration {
    width: 95%;
    height: 30%;
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-weight: 600;
    font-size: 1.5vh;
    flex-direction: row;
}
.itemData .detailsContainer .itemDuration .icon {
    margin-right: 0.1vw;
}