
.button {
    background-color: #F9A02C;
    width: 21vw;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 2vh;
    font-size: 1.5vh;
    color: #fff;
    font-size: 1.5vh;
    font-weight: 600;
    border: none;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    -webkit-appearance: none;
}
