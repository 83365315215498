@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

.navigation {
    width: 100vw;
    height: 10vh;
    background-color: #fff;
    border-bottom-width: 0.05vh;
    border-bottom-style: solid;
    border-bottom-color: #f1f1f1;
    top: 0;
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
}

.navigationLogo {
    width: 20vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.navigationLogoSrc {
    width: 80%;
}

.navigationMenu {
    width: 80vw;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
}

.navigationAccount {
    width: 10vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 2.25vh;
    margin-left: 2.5vw;
}
.accountUsername {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 2.2vh;
}
.accountEmail {
    width: 100%;
    height: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 1.5vh;
    color: #8b8b8b;
}

.navigationItem {
    width: 10vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navigationItem a {
    text-decoration: none !important;
    color: #151515 !important;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 2.25vh;
}
.navigationItemCurrent {
    width: 10vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.navigationItemCurrent a {
    text-decoration: none !important;
    color: #F9A02C !important;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 2.25vh;
}
