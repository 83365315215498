body {
    background-color: #f7f7f7;
    align-items: center;
}

.root {
    background-color: #f7f7f7;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form {
    background-color: #fff;
    width: 25vw;
    height: 50vh;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-style: solid;
    border-color: #e1e1e1;
    border-width: 0.1vh;
}

.logo {
    background-color: #fff;
    width: 25vw;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logoSrc {
    width: 20vw;
}

.formInput {
    width: 20vw;
    height: 3vh;
    margin-top: 2vh;
    padding: 0.5vh;
    font-size: 1.5vh;
    font-weight: 500;
    border-style: solid;
    border-color: #e1e1e1;
    border-width: 0.2vh;
    outline: none;
    -webkit-appearance: none;
}